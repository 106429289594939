import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import About from 'components/about'
import Header from 'components/header'
import Footer from 'components/footer'

const PrivacyPolicyPage = ({ data }) => {
  return (
    <Layout>
      <main className="main">
        <About
          heading={data.privacyPolicyJson.heading}
          content={data.privacyPolicyJson.intro.childMarkdownRemark.html}
        />
      </main>
      <Footer />
    </Layout>
  )
}

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PrivacyPolicyPage

export const query = graphql`
  query PrivacyPolicyPageQuery {
    privacyPolicyJson {
      title
      heading
      intro {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
